import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import SliderMobile from "../components/Slider_mobile"

import {IsMobileDevice} from '../utils'


const Home = ({data, location}) => {
  // console.log("data:",data)
  const [isMobile, setIsMobile] = useState(false)
  const [mobileLight, setMobileLight] = useState(false)
  const [age, setAge] = useState(true);

  useEffect(()=>{
    let local = localStorage.getItem('age') || sessionStorage.getItem('age')
    // console.log("local from index: " + local)
    if (local) setAge(false)
    setIsMobile(IsMobileDevice())
    setMobileLight(IsMobileDevice())
  },[])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Corona",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
          window.location.search.includes("?cmp=")
              ? window.location.search.split("?cmp=")[1].split("&")[0]
              : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "",
      pageType: "Home",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.cervezacorona.com.ar/",
      url_campaign: `${
          window.location.search.includes("utm_campaign=")
              ? window.location.search.split("utm_campaign=")[1]
              : ""
      }`,
      Step_number: "" ,
    })
  }, [])

  const switchState = data?.allPopUpSwitch?.edges[0]?.node?.status
  const popupData = data?.allPopUp?.edges[0]?.node
  const legalsData = data?.allLegals?.edges[0]?.node
  //console.log('popupData',data.allPopUp)
  return (
      <Layout mobileLight={mobileLight} isMobile={isMobile}>
        <SEO title="Home"/>
            {isMobile ?
            <SliderMobile switchState={switchState} popupData={popupData} legalsData={legalsData}/>
            : <Slider switchState={switchState} popupData={popupData} legalsData={legalsData}/> }
      </Layout>
  )

}

export const query = graphql`
   query {
    allPopUpSwitch {
      edges {
        node {
          id
          status
        }
      }
    }
    allPopUp {
      edges {
        node {
          cta
          img
          link
          text_sup
          text_inf
        }
      }
    }
    allLegals {
      edges {
        node {
          text
        }
      }
    }
  }
`

export default Home
